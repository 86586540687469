<template>
  <div class="page-wrapper-table">
    <div class="page-wrapper-table-header">
      <el-select
        class="select-primary pagination-select"
        v-model="pagination.perPage"
        :placeholder="$t('COMMON.PER_PAGE')"
      >
        <el-option
          class="select-primary"
          v-for="item in pagination.perPageOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>

      <el-select
        v-if="!filterStatus"
        :placeholder="$t('COMMON.STATUS')"
        v-model="selectedStatus"
      >
        <el-option :value="0" :label="$t('COMMON.ALL_STATUSES')"> </el-option>
        <el-option
          v-for="(value, key) in statusesOptions"
          :key="key"
          :value="key"
          :label="value"
        >
        </el-option>
      </el-select>

      <organization-selector
        v-if="
          !filterOrganization &&
          !filterIssuer &&
          !filterPurchasesOrder &&
          $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
        "
        @organizationChanged="
          (organizationId) => (selectedOrganization = organizationId)
        "
      />

      <base-input
        v-if="
          !filterPurchasesOrder &&
          $currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)
        "
        :placeholder="`${$t('COMMON.LOCATIONS')}`"
      >
        <locations-selector
          @locationsChanged="(locations) => (selectedLocations = locations)"
        />
      </base-input>

      <base-input class="dates" placeholder="Dates">
        <flat-picker
          :config="{
            allowInput: true,
            mode: 'range',
            locale: flatPickrLocale,
          }"
          class="form-control datepicker"
          v-model="dateRange"
          placeholder="Dates"
        >
        </flat-picker>
      </base-input>

      <base-input
        v-model="query"
        type="search"
        prepend-icon="fas fa-search"
        :placeholder="$t('COMMON.CODE')"
        clearable
      />
    </div>
    <div class="page-wrapper-table-body">
      <div class="page-wrapper-table-body-inner">
        <el-table
          class="align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="purchasesDeliveries"
          @sort-change="sortChange"
          row-class-name="cursor-pointer"
          @row-click="
            (row) => {
              viewPurchasesDelivery(row);
            }
          "
        >
          <div class="table-loading" slot="empty" v-if="loading">
            <img src="/img/loading.gif" />
          </div>
          <el-table-column
            :label="$t('COMMON.CODE')"
            prop="code"
            sortable="custom"
            min-width="220"
          />
          <el-table-column
            :label="$t('COMMON.STATUS')"
            prop="status"
            min-width="220"
          >
            <template slot-scope="{ row }">
              <purchases-delivery-status-badge :purchasesDelivery="row" />
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('COMMON.ISSUER')"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              <object-link :object="row.issuer" />
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('COMMON.LOCATIONS')"
            sortable="custom"
            min-width="220"
            v-if="
              !filterPurchasesOrder &&
              $currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)
            "
          >
            <template v-slot="{ row }">
              <locations :locations="row.allowedLocations" />
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('COMMON.ORGANIZATION')"
            sortable="custom"
            min-width="220"
            v-if="
              !filterOrganization &&
              !filterPurchasesOrder &&
              $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
            "
          >
            <template v-slot="{ row }">
              <organization :organization="row.organization" />
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('PURCHASES_DELIVERIES.EXPIRATION_TIME')"
            prop="expiration_time"
            sortable="custom"
            min-width="220"
          />
          <el-table-column
            :label="$t('PURCHASES_DELIVERIES.CREATED_AT')"
            prop="created_at"
            sortable="custom"
            min-width="220"
          />
          <el-table-column fixed="right" min-width="120">
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip
                :content="$t('COMMON.VIEW')"
                placement="top"
                :class="{
                  disabled: !$currentUserCan(
                    $permissions.PERM_VIEW_PURCHASES_DELIVERIES
                  ),
                }"
              >
                <a
                  type="text"
                  @click="viewPurchasesDelivery(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i
                    class="fa-light fa-arrow-up-right-and-arrow-down-left-from-center"
                  ></i>
                </a>
              </el-tooltip>
              <el-tooltip
                :content="$t('COMMON.DELETE')"
                placement="top"
                :class="{
                  disabled: !(
                    $currentUserCan(
                      $permissions.PERM_DELETE_PURCHASES_DELIVERIES
                    ) && row.status == DELIVERY_STATUS_DRAFT
                  ),
                }"
              >
                <a
                  type="text"
                  @click="deletePurchasesDelivery(row.id)"
                  class="table-action table-action-delete"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fa-light fa-trash-can"></i>
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div slot="footer" class="page-wrapper-table-footer">
      <p class="card-category">
        {{
          $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
            from: total ? from + 1 : 0,
            to: to,
            of: total,
          })
        }}
        <span v-if="selectedRows.length">
          &nbsp; &nbsp;
          {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
        </span>
      </p>
      <base-pagination
        class="pagination-no-bdelivery"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import flatPicker from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";
import "flatpickr/dist/flatpickr.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  deliveriesStatusesOption,
  DELIVERY_STATUS_DRAFT,
} from "@/constants/deliveries";
import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import PurchasesDeliveryStatusBadge from "./PurchasesDeliveryStatusBadge.vue";

export default {
  name: "purchases-delivery-list-table",

  components: {
    BasePagination,
    flatPicker,
    OrganizationSelector,
    LocationsSelector,
    PurchasesDeliveryStatusBadge,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterPurchasesOrder: {
      type: String,
      default: null,
      description: "Order id",
    },
    filterIssuer: {
      type: Object,
      default: null,
      description: "Object with 'issuer_id' and 'issuer_type' ",
    },
    filterStatus: {
      type: String,
      default: null,
      description: "Status",
    },
  },

  data() {
    return {
      flatPickrLocale: French,
      query: null,
      selectedRows: [],
      sort: "-updated_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      purchasesDeliveries: [],
      loading: true,
      dateRange: null,
      selectedOrganization: null,
      selectedLocations: null,
      selectedStatus: null,
      selectedIssuer: null,
      statusesOptions: deliveriesStatusesOption,
      DELIVERY_STATUS_DRAFT: DELIVERY_STATUS_DRAFT,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocations: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterIssuer: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedIssuer: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterPurchasesOrder: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterStatus: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedStatus: {
      handler: "getListDebounced",
      immediate: true,
    },
    dateRange: {
      handler: "getListDebounced",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        const vDateRange = this.dateRange
          ? this.dateRange.split(this.flatPickrLocale.rangeSeparator)
          : [];
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { code: this.query } : {}),
            ...(vDateRange.length == 2
              ? {
                  created_at: [
                    vDateRange[0] + " 00:00:00",
                    vDateRange[1] + " 23:59:59",
                  ],
                }
              : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization,allowedLocations,issuer",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.filterPurchasesOrder) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              purchasesOrder: this.filterPurchasesOrder,
            },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }
        if (this.filterIssuer) {
          if (this.filterIssuer.type && this.filterIssuer.id) {
            params = {
              ...params,
              filter: {
                ...params.filter,
                issuer_type: this.filterIssuer.type,
                issuer_id: this.filterIssuer.id,
              },
            };
          }
        }
        if (this.selectedIssuer) {
          if (this.selectedIssuer.type && this.selectedIssuer.id) {
            params = {
              ...params,
              filter: {
                ...params.filter,
                issuer_type: this.selectedIssuer.type,
                issuer_id: this.selectedIssuer.id,
              },
            };
          }
        }
        if (this.filterStatus) {
          params = {
            ...params,
            filter: { ...params.filter, status: this.filterStatus },
          };
        }
        if (this.selectedStatus) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              status: this.selectedStatus,
            },
          };
        }
        if (this.selectedLocations) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              allowedLocations: this.selectedLocations.map((loc) => loc.id),
            },
          };
        }

        await this.$store.dispatch("purchasesDeliveries/list", params);
        this.purchasesDeliveries =
          this.$store.getters["purchasesDeliveries/list"];
        this.total = this.$store.getters["purchasesDeliveries/listTotal"];
        this.loading = false;
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async deletePurchasesDelivery(id) {
      const confirmation = await swal.fire({
        title: this.$t("PURCHASES_DELIVERIES.DELETE_THIS_PURCHASES_DELIVERY"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          await this.$store.dispatch("purchasesDeliveries/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("PURCHASES_DELIVERIES.PURCHASES_DELIVERY_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    viewPurchasesDelivery(purchasesDelivery) {
      this.$emit("onViewPurchasesDelivery", purchasesDelivery);
      /* this.$router.push({
        name: "View PurchasesDelivery",
        params: { id: purchasesDelivery.id },
      }); */
    },

    editPurchasesDelivery(purchasesDelivery) {
      this.$emit("onEditPurchasesDelivery", purchasesDelivery);
      /* this.$router.push({
        name: "Edit PurchasesDelivery",
        params: { id: row.id },
      }); */
    },

    sortChange({ prop, purchasesDelivery }) {
      if (purchasesDelivery === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
