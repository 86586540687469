var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-wrapper-table"},[_c('div',{staticClass:"page-wrapper-table-header"},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"placeholder":_vm.$t('COMMON.PER_PAGE')},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1),(!_vm.filterStatus)?_c('el-select',{attrs:{"placeholder":_vm.$t('COMMON.STATUS')},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}},[_c('el-option',{attrs:{"value":0,"label":_vm.$t('COMMON.ALL_STATUSES')}}),_vm._l((_vm.statusesOptions),function(value,key){return _c('el-option',{key:key,attrs:{"value":key,"label":value}})})],2):_vm._e(),(
        !_vm.filterOrganization &&
        !_vm.filterIssuer &&
        !_vm.filterPurchasesOrder &&
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS)
      )?_c('organization-selector',{on:{"organizationChanged":(organizationId) => (_vm.selectedOrganization = organizationId)}}):_vm._e(),(
        !_vm.filterPurchasesOrder &&
        _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS)
      )?_c('base-input',{attrs:{"placeholder":`${_vm.$t('COMMON.LOCATIONS')}`}},[_c('locations-selector',{on:{"locationsChanged":(locations) => (_vm.selectedLocations = locations)}})],1):_vm._e(),_c('base-input',{staticClass:"dates",attrs:{"placeholder":"Dates"}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
          allowInput: true,
          mode: 'range',
          locale: _vm.flatPickrLocale,
        },"placeholder":"Dates"},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('base-input',{attrs:{"type":"search","prepend-icon":"fas fa-search","placeholder":_vm.$t('COMMON.CODE'),"clearable":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_c('div',{staticClass:"page-wrapper-table-body"},[_c('div',{staticClass:"page-wrapper-table-body-inner"},[_c('el-table',{staticClass:"align-items-center table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.purchasesDeliveries,"row-class-name":"cursor-pointer"},on:{"sort-change":_vm.sortChange,"row-click":(row) => {
            _vm.viewPurchasesDelivery(row);
          }}},[(_vm.loading)?_c('div',{staticClass:"table-loading",attrs:{"slot":"empty"},slot:"empty"},[_c('img',{attrs:{"src":"/img/loading.gif"}})]):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.CODE'),"prop":"code","sortable":"custom","min-width":"220"}}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.STATUS'),"prop":"status","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('purchases-delivery-status-badge',{attrs:{"purchasesDelivery":row}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.ISSUER'),"sortable":"custom","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('object-link',{attrs:{"object":row.issuer}})]}}])}),(
            !_vm.filterPurchasesOrder &&
            _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS)
          )?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.LOCATIONS'),"sortable":"custom","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('locations',{attrs:{"locations":row.allowedLocations}})]}}],null,false,4110342845)}):_vm._e(),(
            !_vm.filterOrganization &&
            !_vm.filterPurchasesOrder &&
            _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS)
          )?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.ORGANIZATION'),"sortable":"custom","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('organization',{attrs:{"organization":row.organization}})]}}],null,false,3772690256)}):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('PURCHASES_DELIVERIES.EXPIRATION_TIME'),"prop":"expiration_time","sortable":"custom","min-width":"220"}}),_c('el-table-column',{attrs:{"label":_vm.$t('PURCHASES_DELIVERIES.CREATED_AT'),"prop":"created_at","sortable":"custom","min-width":"220"}}),_c('el-table-column',{attrs:{"fixed":"right","min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return _c('div',{staticClass:"table-actions"},[_c('el-tooltip',{class:{
                disabled: !_vm.$currentUserCan(
                  _vm.$permissions.PERM_VIEW_PURCHASES_DELIVERIES
                ),
              },attrs:{"content":_vm.$t('COMMON.VIEW'),"placement":"top"}},[_c('a',{staticClass:"table-action",staticStyle:{"cursor":"pointer"},attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.viewPurchasesDelivery(row)}}},[_c('i',{staticClass:"fa-light fa-arrow-up-right-and-arrow-down-left-from-center"})])]),_c('el-tooltip',{class:{
                disabled: !(
                  _vm.$currentUserCan(
                    _vm.$permissions.PERM_DELETE_PURCHASES_DELIVERIES
                  ) && row.status == _vm.DELIVERY_STATUS_DRAFT
                ),
              },attrs:{"content":_vm.$t('COMMON.DELETE'),"placement":"top"}},[_c('a',{staticClass:"table-action table-action-delete",staticStyle:{"cursor":"pointer"},attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.deletePurchasesDelivery(row.id)}}},[_c('i',{staticClass:"fa-light fa-trash-can"})])])],1)}}])})],1)],1)]),_c('div',{staticClass:"page-wrapper-table-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('p',{staticClass:"card-category"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", { from: _vm.total ? _vm.from + 1 : 0, to: _vm.to, of: _vm.total, }))+" "),(_vm.selectedRows.length)?_c('span',[_vm._v("     "+_vm._s(_vm.$t("COMMON.X_LINES_SELECTED", { count: _vm.selectedRows.length }))+" ")]):_vm._e()]),_c('base-pagination',{staticClass:"pagination-no-bdelivery",attrs:{"per-page":_vm.pagination.perPage,"total":_vm.total},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }